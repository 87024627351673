import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "@/store/modalSlice";
import { useWeb3 } from "@/hooks/useWeb3";
import { useRouter } from "next/navigation";

export const AuthModal = () => {
  const dispatch = useDispatch();
  const { connectWallet } = useWeb3();
  const router = useRouter();

  const handleConnectWallet = async () => {
    dispatch(closeModal());
    await connectWallet();
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 px-4">
      <div className="bg-white text-center rounded-2xl border border-gray-200 shadow-xl p-8 relative overflow-hidden max-w-md w-full mx-6">
        <button
          onClick={() => {
            router.push("/");
            dispatch(closeModal());
          }}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="mb-6 flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 text-black/70"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
        </div>

        <h2 className="text-xl font-medium text-black mb-4">
          Authentication Required
        </h2>

        <p className="text-black/80 text-sm mb-6">
          Please connect your wallet to access this feature
        </p>

        <button
          onClick={handleConnectWallet}
          className="bg-primary text-white px-6 py-2 rounded-full text-[15px] font-medium cursor-pointer transition-colors w-full hover:bg-primary-hover"
        >
          Connect Wallet
        </button>
      </div>
    </div>
  );
};
