import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalState, ModalProps } from '@/types/modal';
import { ModalType } from '@/constants/modalTypes';

const initialState: ModalState = {
  type: null,
  props: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{ type: ModalType; props: ModalProps }>
    ) => {
      console.log('openModal', action.payload);
      state.type = action.payload.type;
      state.props = action.payload.props;
    },
    closeModal: (state) => {
      state.type = null;
      state.props = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer; 