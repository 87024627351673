"use client";

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "@/store/modalSlice";
import { useToast } from "@/components/Toast/Toast";
import { FraudEvaluation } from "@/services/api/fraudEvaluationService";
import ReportTemplate from "@/components/ReportTemplate/ReportTemplate";
import ReactDOM from "react-dom/client";
import TermsModal from "../TermsModal/TermsModal";
import PrivacyPolicyModal from "../PrivacyPolicyModal/PrivacyPolicyModal";
import Link from "next/link";


interface ExportOptions {
  riskBreakdown: boolean;
  aiInsightsAndRecommendations: boolean;
  transactionHistory: boolean;
  includeVisuals: boolean;
  fileFormat: "PDF" | "CSV" | "EXCEL";
}

interface ExportModalProps {
  wallet: FraudEvaluation;
}

const ExportModal: React.FC<ExportModalProps> = ({ wallet }) => {
  const dispatch = useDispatch();
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { addToast } = useToast();

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);


  const [options, setOptions] = useState<ExportOptions>({
    riskBreakdown: true,
    aiInsightsAndRecommendations: true,
    transactionHistory: false,
    includeVisuals: true,
    fileFormat: "PDF",
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleGenerateReport = async () => {
    if (!agreedToTerms) {
      addToast(
        "Agreement Required",
        "Please agree to Terms of Service and Privacy Policy",
        "warning"
      );
      return;
    }

    if (
      !options.riskBreakdown &&
      !options.aiInsightsAndRecommendations &&
      !options.transactionHistory
    ) {
      addToast(
        "Section Required",
        "Please select at least one section to include in the report",
        "warning"
      );
      return;
    }

    setIsGenerating(true);
    addToast("Please Wait", "Generating your report...", "info");

    try {
      // Create a temporary container
      const element = document.createElement("div");
      element.style.width = "795px";
      element.style.padding = "0px";
      element.style.backgroundColor = "white";
      document.body.appendChild(element);

      // Render the report template into the container
      const root = ReactDOM.createRoot(element);
      root.render(<ReportTemplate wallet={wallet} options={options} />);


      const jspdf = await import("jspdf");
      const html2canvas = await import("html2canvas");

      // Use the default export from the imports
      const jsPDF = jspdf.default;
      const h2c = html2canvas.default;

      // Generate canvas from the element
      const canvas = await h2c(element, {
        scale: 2,
        useCORS: true,
        logging: false
      });

      // Create PDF
      const pdf = new jsPDF("portrait", "mm", "a4");

      // Add image to PDF
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // If the content is longer than one page, add additional pages
      let heightLeft = pdfHeight - pdf.internal.pageSize.getHeight();
      let position = -pdf.internal.pageSize.getHeight();

      while (heightLeft > 0) {
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
        position -= pdf.internal.pageSize.getHeight();
      }

      // Save the PDF
      pdf.save(`Wallet_Report_${wallet.target.slice(0, 8)}.pdf`);

      // Clean up
      root.unmount();
      document.body.removeChild(element);

      // Show success message
      setTimeout(() => {
        addToast("Success", "Report generated successfully!", "success");
        handleClose();
      }, 500);
    } catch (error) {
      console.error("Error generating report:", error);
      addToast(
        "Error",
        "Failed to generate report. Please try again.",
        "error"
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handlePreviewReport = () => {
    if (
      !options.riskBreakdown &&
      !options.aiInsightsAndRecommendations &&
      !options.transactionHistory
    ) {
      addToast(
        "Section Required",
        "Please select at least one section to include in the report",
        "warning"
      );
      return;
    }
    setShowPreview(true);
  };

  // The rest of your UI component remains the same
  return (
    <div className="relative">
      <TermsModal isOpen={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)} />
      <PrivacyPolicyModal isOpen={isPrivacyModalOpen} onClose={() => setIsPrivacyModalOpen(false)} />

      <div className="fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-50 px-4">
        <div className="bg-white rounded-2xl p-8 max-w-[900px] w-full relative shadow-xl max-h-[90vh] overflow-y-auto">
          {/* Background Image */}
          <div
            className="absolute top-0 left-0 w-full h-full bg-no-repeat rounded-2xl"
            style={{
              backgroundImage: `url('/bg-11.png')`,
              backgroundSize: "cover",
              zIndex: -1,
              opacity: 0.08,
            }}
          />

          {/* Header */}
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold text-[#1A1A1A] mb-3">
              Export Report
            </h2>
            <p className="text-[#666666] text-lg max-w-2xl mx-auto">
              Customize your report based on your requirements and export.
            </p>
          </div>

          {/* Sections Selection */}
          <div className="mb-8">
            <h3 className="text-2xl font-bold mb-4 text-[#1A1A1A]">
              Choose sections to include
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-[#F8FAFD] rounded-xl p-4 hover:shadow-md transition-all duration-200 border border-[#E8EFF8]">
                <label className="flex items-center gap-3 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded accent-[#0066FF]"
                    checked={options.riskBreakdown}
                    onChange={(e) =>
                      setOptions({
                        ...options,
                        riskBreakdown: e.target.checked,
                      })
                    }
                  />
                  <span className="text-lg font-medium">Risk Breakdown</span>
                </label>
              </div>
              <div className="bg-[#F8FAFD] rounded-xl p-4 hover:shadow-md transition-all duration-200 border border-[#E8EFF8]">
                <label className="flex items-center gap-3 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded accent-[#0066FF]"
                    checked={options.aiInsightsAndRecommendations}
                    onChange={(e) =>
                      setOptions({
                        ...options,
                        aiInsightsAndRecommendations: e.target.checked,
                      })
                    }
                  />
                  <span className="text-lg font-medium">
                    AI Insights & Recommendations
                  </span>
                </label>
              </div>
              <div className="bg-[#F8FAFD] rounded-xl p-4 hover:shadow-md transition-all duration-200 border border-[#E8EFF8]">
                <label className="flex items-center gap-3 cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-5 h-5 rounded accent-[#0066FF]"
                    checked={options.transactionHistory}
                    onChange={(e) =>
                      setOptions({
                        ...options,
                        transactionHistory: e.target.checked,
                      })
                    }
                    disabled={true}
                  />
                  <span className="text-lg font-medium text-gray-400">
                    Transaction History
                  </span>
                </label>
              </div>
            </div>
          </div>

          {/* File Format and Visual Elements */}
          <div className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-8 gap-6 p-5 bg-[#F0F7FF] rounded-xl border border-[#D5E6FF]">
            <div className="flex items-center gap-4">
              <h3 className="text-xl font-bold text-[#1A1A1A]">File format:</h3>
              <select
                className="px-4 py-2 border border-[#D5E6FF] rounded-lg bg-white text-base font-medium text-[#2F7BD3] disabled:opacity-60 disabled:cursor-not-allowed shadow-sm"
                value={options.fileFormat}
                onChange={(e) =>
                  setOptions({
                    ...options,
                    fileFormat: e.target.value as ExportOptions["fileFormat"],
                  })
                }
                disabled={true}
              >
                <option value="PDF">PDF</option>
                <option value="CSV">CSV</option>
                <option value="EXCEL">EXCEL</option>
              </select>
            </div>
            <label className="flex items-center gap-4 cursor-not-allowed px-4 py-2 bg-white rounded-lg border border-[#D5E6FF] shadow-sm">
              <span className="text-base font-bold">Include visuals</span>
              <input
                type="checkbox"
                className="w-5 h-5 cursor-not-allowed rounded accent-[#0066FF]"
                checked={options.includeVisuals}
                onChange={(e) =>
                  setOptions({ ...options, includeVisuals: e.target.checked })
                }
                disabled={true}
              />
            </label>
          </div>

          {/* Terms Agreement */}
          <div className="mb-8 p-4 bg-[#F8FAFD] rounded-xl border border-[#E8EFF8]">
            <label className="flex items-center gap-3 cursor-pointer">
              <input
                type="checkbox"
                className="w-5 h-5 rounded accent-[#0066FF]"
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
              />
              <span className="text-base text-[#626262]">
                I agree to{" "}
                <Link
                  href="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#0066FF] hover:underline font-semibold"
                >
                  Terms of Services
                </Link>{" "}
                and{" "}
                <Link
                    href="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#0066FF] hover:underline font-semibold"
                >
                  Privacy Policy
                </Link>
              </span>
            </label>
          </div>

          {/* Preview Section */}
          {showPreview && (
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-[#1A1A1A]">
                Preview
              </h3>
              <div className="border border-[#E8EFF8] rounded-xl p-4 max-h-[400px] overflow-y-auto">
                <ReportTemplate wallet={wallet} options={options} />
              </div>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex flex-col-reverse md:flex-row items-center justify-end gap-4">
            <button
              className="w-full md:w-auto px-8 py-3.5 text-[#0066FF] hover:bg-[#F4F6FB] rounded-full border border-[#0066FF] transition-colors text-base font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleClose}
              disabled={isGenerating}
            >
              Cancel
            </button>
            <button
              className="w-full md:w-auto px-8 py-3.5 bg-[#F0F7FF] text-[#0066FF] rounded-full hover:bg-[#E1EFFF] transition-colors text-base font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handlePreviewReport}
              disabled={isGenerating}
            >
              Preview Report
            </button>
            <button
              className={`
                w-full md:w-auto px-8 py-3.5 bg-[#0066FF] text-white rounded-full
                hover:bg-[#0052CC] transition-colors 
                disabled:opacity-50 disabled:cursor-not-allowed 
                text-base font-medium flex items-center justify-center min-w-[160px]
                shadow-lg shadow-blue-200/50
              `}
              onClick={handleGenerateReport}
              disabled={!agreedToTerms || isGenerating}
            >
              {isGenerating ? (
                <div className="flex items-center gap-2">
                  <div className="animate-spin w-5 h-5 border-2 border-white border-t-transparent rounded-full" />
                  <span>Generating...</span>
                </div>
              ) : (
                "Generate Report"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;