'use client'
import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';

interface ErrorDialogProps {
  isOpen: boolean;
  onClose?: () => void;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ isOpen, onClose }) => {
  const router = useRouter();

  if (!isOpen) return null;

  const handleBackHome = () => {
    router.push('/');
    onClose?.();
  };

  return (
    <div className="fixed inset-0 bg-black/5 backdrop-blur-[4px] z-50 flex items-center justify-center p-4">
      <div className="bg-[#F3F5F6] rounded-[10px] shadow-lg w-full max-w-[540px] px-8 py-8 text-center">
        {/* Error Icon */}
        <div className="flex justify-center">
          <Image 
            src="/images/error-icon.png"
            alt="Error Icon"
            width={200}
            height={200}
            className="object-contain"
          />
        </div>

        {/* Error Message */}
        <h2 className="text-[32px] font-medium text-[#1A1A1A] mt-4 mb-2">
          Operational Error!
        </h2>
        <p className="text-[#666666] text-[18px] leading-7 mb-8 max-w-[400px] mx-auto">
          System error! Please contact your system administrator.
        </p>

        {/* Back Home Button */}
        <button
          onClick={handleBackHome}
          className="bg-primary text-white text-[18px] px-12 py-2.5 rounded-full hover:bg-[#2362B0] transition-colors font-medium btn-primary"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export default ErrorDialog;