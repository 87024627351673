import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "@/store/modalSlice";
import { KeyRound } from "lucide-react";
import { useToast } from "@/components/Toast/Toast";
import { deleteAccount } from "@/store/userSlice";
import { AppDispatch } from "@/store/store";
import { secureStorage } from "@/utils/encryption";

interface DeleteAccountModalProps {
  onClose?: () => void;
  is2FAEnabled: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  onClose,
  is2FAEnabled,
}) => {
  const [password, setPassword] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { addToast } = useToast();

  useEffect(() => {
    if (!is2FAEnabled) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [is2FAEnabled]);

  const handleConfirm = async () => {
    if (!is2FAEnabled && !password) {
      addToast("Error", "Please enter your password", "error");
      return;
    }

    setIsDeleting(true);
    try {
      // Directly dispatch the deleteAccount action here
      await dispatch(
        deleteAccount({
          currentPassword: password,
          twoFactorAuthentication: null,
        })
      ).unwrap();

      // Handle successful deletion
      addToast("Success", "Account deletion request submitted", "success");
      // Clear all auth-related storage items
      secureStorage.removeItem("auth_token");
      secureStorage.removeItem("nonce");
      secureStorage.removeItem("walletConnection");
      secureStorage.removeItem("wallet_address");
      // Close the modal
      dispatch(closeModal());

      // Redirect to home page after a delay
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } catch (error) {
      console.error("Error in delete confirmation:", error);
      addToast("Error", "Failed to delete account", "error");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModal());
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4">
      <div className="bg-white rounded-xl p-6 max-w-[400px] w-full relative">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Confirm Account Deletion
        </h3>
        <p className="text-gray-600 text-sm mb-6">
          {is2FAEnabled
            ? "You'll need to verify your identity to delete your account."
            : "This action cannot be undone. Please enter your password to confirm deletion."}
        </p>

        {!is2FAEnabled && (
          <div className="relative mb-6">
            <KeyRound className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              ref={inputRef}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && password) {
                  handleConfirm();
                }
              }}
              placeholder="Enter your password"
              className="w-full pl-12 pr-4 py-2.5 rounded-lg border border-gray-200 text-gray-900 focus:outline-none focus:border-red-500"
            />
          </div>
        )}

        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            disabled={(!password && !is2FAEnabled) || isDeleting}
            className={`
              px-4 py-2 bg-red-600 text-white rounded-lg
              hover:bg-red-700 transition-colors
              disabled:opacity-50 disabled:cursor-not-allowed
              flex items-center justify-center min-w-[100px]
            `}
          >
            {isDeleting ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full" />
                <span>Deleting...</span>
              </div>
            ) : (
              "Delete Account"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
