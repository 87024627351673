import axiosInstance from "./axios";
import { getUserIdFromToken } from "@/utils/jwt";

export interface FraudCaseRequest {
  additional_information: string;
  fraud_type: string;
  incident_end_date: string | null;
  incident_start_date: string;
  transaction_hashes: string[];
  user_id: string | null;
  wallet_address: string;
}

export const fraudCasesService = {
  async submitFraudCase(data: FraudCaseRequest) {
    const userId = getUserIdFromToken();
    try {
      const response = await axiosInstance.post(
        `/api/p/fes/v1/fraud-cases`,
        {
          ...data,
          user_id: userId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting fraud case:", error);
      throw error;
    }
  }
}; 