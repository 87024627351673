import React from "react";
import { format } from "date-fns";

const ReportTemplate = ({ wallet, options }) => {
  // Function to format factor names from snake_case to Title Case
  const formatFactorName = (factor) => {
    return factor.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  };

  // Function to determine color based on score
  const getScoreColor = (score) => {
    if (score >= 65) return "#00B34D";
    if (score >= 49) return "#FFCD43";
    return "#FF4843";
  };

  // For PDF export, create a plain HTML version with minimal CSS
  return (
    <div
      id="report-template"
      style={{
        backgroundColor: "white",
        width: "210mm",
        margin: "0 auto",
        position: "relative",
        fontFamily: "Arial, sans-serif",
        padding: "5px",
      }}
    >
      {/* Header - Changed from blue background to white with blue bottom border */}
      <div
        style={{
          width: "100%",
          height: "74px",
          backgroundColor: "white", // Changed from #2F7AD3 to white
          padding: "0 24px",
          color: "#1A1A1A", // Changed from white to dark text
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {/* Logo */}
          <div
            style={{
              marginRight: "12px",
              height: "42px",
              position: "relative",
              backgroundColor: "white",
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            <img
              src="/png-2-trimmed.png"
              alt="MonChain Logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <div>
            <h1 style={{ fontSize: "20px", fontWeight: "bold", margin: 0 }}>
              Wallet Evaluation Report
            </h1>
            {/* Removed "Generated by MonChain" from here */}
          </div>
        </div>
      </div>

      {/* Wallet Details */}
      <div
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div
          style={{ width: "100%", height: "2px", backgroundColor: "#2F7AD3" }}
        ></div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginBottom: "4px",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              color: "#666666",
              width: "128px",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Wallet Address:
          </p>
          <p
            style={{
              fontWeight: "bold",
              color: "#1A1A1A",
              wordBreak: "break-all",
              fontSize: "14px",
              margin: "0",
            }}
          >
            {wallet.target}
          </p>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          <p
            style={{
              color: "#666666",
              width: "128px",
              fontSize: "14px",
              margin: "0",
            }}
          >
            Generated on:
          </p>
          <p
            style={{
              fontWeight: "bold",
              color: "#1A1A1A",
              fontSize: "14px",
              margin: "0",
            }}
          >
            {format(new Date(), "MMM dd, yyyy")}
          </p>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#E8EFF8",
            margin: "8px 0",
          }}
        ></div>
      </div>

      {options.riskBreakdown && (
        <>
          {/* Risk Score Section */}
          <div
            style={{
              margin: "0 24px 16px",
              padding: "16px",
              backgroundColor: "#F8FAFD",
              borderRadius: "12px",
            }}
          >
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#1A1A1A",
                marginBottom: "12px",
              }}
            >
              Risk Score
            </h2>

            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                <tr>
                  <td style={{ width: "128px", verticalAlign: "middle" }}>
                    {/* Score Circle Container - Aligned top with legend */}
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {/* Circle container with fixed height */}
                      <div
                        style={{
                          width: "96px",
                          height: "96px",
                          margin: "0 auto",
                          position: "relative",
                        }}
                      >
                        {/* SVG with both progress circle and text for perfect alignment */}
                        <svg
                          width="96"
                          height="96"
                          viewBox="0 0 96 96"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                          }}
                        >
                          {/* Background circle */}
                          <circle
                            cx="48"
                            cy="48"
                            r="44"
                            fill="transparent"
                            stroke="#E8EFF8"
                            strokeWidth="8"
                          />

                          {/* Progress circle - rotated to start from top */}
                          <circle
                            cx="48"
                            cy="48"
                            r="44"
                            fill="transparent"
                            stroke={getScoreColor(wallet.fraud_score || 0)}
                            strokeWidth="8"
                            strokeDasharray={`${
                              (wallet.fraud_score || 0) * 2.76
                            } 276`}
                            strokeLinecap="round"
                            transform="rotate(-90 48 48)"
                          />

                          {/* Central white circle for donut effect */}
                          <circle cx="48" cy="48" r="40" fill="white" />

                          {/* Score text - perfectly centered */}
                          <text
                            x="48"
                            y="48"
                            fill={getScoreColor(wallet.fraud_score || 0)}
                            fontSize="32px"
                            fontWeight="bold"
                            fontFamily="Arial, sans-serif"
                            textAnchor="middle"
                            dominantBaseline="central"
                            dy="2" // Small adjustment for perfect optical centering
                          >
                            {Math.round(wallet.fraud_score || 0)}
                          </text>
                        </svg>
                      </div>

                      {/* Risk level text BELOW the circle with margin */}
                      <div
                        style={{
                          marginTop: "8px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#666666",
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                        >
                          {wallet?.risk_level} Risk
                        </span>
                      </div>
                    </div>
                  </td>

                  <td
                    style={{
                      width: "150px",
                      verticalAlign: "top",
                      paddingTop: "8px",
                    }}
                  >
                    {/* Risk Legend using SVG for perfect alignment */}
                    <svg width="150" height="80" viewBox="0 0 150 80">
                      {/* High Risk */}
                      <circle cx="8" cy="12" r="6" fill="#FF4843" />
                      <text
                        x="20"
                        y="16"
                        fontSize="12"
                        fontFamily="Arial, sans-serif"
                      >
                        00 - 49 - High Risk
                      </text>

                      {/* Medium Risk */}
                      <circle cx="8" cy="40" r="6" fill="#FFCD43" />
                      <text
                        x="20"
                        y="44"
                        fontSize="12"
                        fontFamily="Arial, sans-serif"
                      >
                        49 - 65 - Medium Risk
                      </text>

                      {/* Low Risk */}
                      <circle cx="8" cy="68" r="6" fill="#00B34D" />
                      <text
                        x="20"
                        y="72"
                        fontSize="12"
                        fontFamily="Arial, sans-serif"
                      >
                        65 - 100 - Low Risk
                      </text>
                    </svg>
                  </td>

                  <td style={{ verticalAlign: "top", paddingLeft: "16px" }}>
                    {/* Risk Breakdown */}
                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#1A1A1A",
                        margin: "0 0 12px 0",
                      }}
                    >
                      Risk Breakdown
                    </h3>

                    {wallet.risk_breakdown &&
                      wallet.risk_breakdown.map((factor, index) => (
                        <div style={{ marginBottom: "10px" }} key={index}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "12px",
                              marginBottom: "4px",
                            }}
                          >
                            <span style={{ color: "#666666" }}>
                              {formatFactorName(factor.factor)}
                            </span>
                            <span style={{ color: "#1A1A1A" }}>
                              {factor.score} ({factor.weight}%)
                            </span>
                          </div>

                          {/* Progress bar as HTML table for better PDF compatibility */}
                          <table
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                              tableLayout: "fixed",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: `${factor.score}%`,
                                    height: "8px",
                                    padding: 0,
                                    backgroundColor: getScoreColor(
                                      factor.score
                                    ),
                                  }}
                                ></td>
                                <td
                                  style={{
                                    width: `${100 - factor.score}%`,
                                    height: "8px",
                                    padding: 0,
                                    backgroundColor: "#E8EFF8",
                                  }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Evaluation Summary */}
          <div
            style={{
              margin: "0 24px 16px",
              padding: "16px",
              backgroundColor: "#F8FAFD",
              borderRadius: "12px",
            }}
          >
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#1A1A1A",
                marginTop: 0,
                marginBottom: "8px",
              }}
            >
              Evaluation Summary
            </h2>
            <p style={{ fontSize: "12px", color: "#666666", margin: 0 }}>
              {wallet.evaluation_comment || "No evaluation comment available."}
            </p>
          </div>
        </>
      )}

      {/* AI Insights */}
      {options.aiInsightsAndRecommendations &&
        wallet.recommendations &&
        wallet.recommendations.length > 0 && (
          <div
            style={{
              margin: "0 24px 16px",
              padding: "16px",
              backgroundColor: "#F8FAFD",
              borderRadius: "12px",
            }}
          >
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#1A1A1A",
                marginTop: 0,
                marginBottom: "12px",
              }}
            >
              AI Insights & Recommendations
            </h2>

            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                {wallet.recommendations.map((rec, index) => {
                  const content =
                    rec.content || rec.description || rec.toString();
                  const isCompleted = rec.completed === true; // Check if recommendation is completed
                  const getColorClass = () => {
                    if (rec.urgency === "high") return "#FF4843";
                    if (rec.urgency === "medium") return "#FFCD43";
                    return "#00B34D";
                  };
                  return (
                    <tr key={index} style={{ marginBottom: "12px" }}>
                      <td
                        style={{
                          width: "4px",
                          verticalAlign: "top",
                          paddingRight: "8px",
                        }}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "32px",
                            borderRadius: "4px",
                            backgroundColor: getColorClass(),
                            marginTop: "4px",
                          }}
                        ></div>
                      </td>
                      <td
                        style={{
                          width: "24px",
                          verticalAlign: "top",
                          paddingRight: "8px",
                        }}
                      >
                        {/* SVG-based checkbox for perfect alignment */}
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{
                            marginTop: "2px", // Adjusted to align with text
                          }}
                        >
                          {/* Checkbox */}
                          <rect
                            x="4"
                            y="4"
                            width="16"
                            height="16"
                            rx="2"
                            fill={isCompleted ? getColorClass() : "white"}
                            stroke={isCompleted ? getColorClass() : "#E8EFF8"}
                            strokeWidth="1"
                          />

                          {/* Checkmark - only visible when completed */}
                          {isCompleted && (
                            <path
                              d="M7 12L10 15L17 8"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              fill="none"
                            />
                          )}
                        </svg>
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#666666",
                            margin: "0",
                            lineHeight: "20px", // Improved line height for better alignment
                          }}
                        >
                          {content}
                        </p>
                        {rec.date_created && (
                          <span
                            style={{
                              fontSize: "10px",
                              color: "#999999",
                              display: "block",
                              marginTop: "2px",
                            }}
                          >
                            {format(new Date(rec.date_created), "yyyy-MM-dd")}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

      {/* Transaction History */}
      {options.transactionHistory && (
        <div
          style={{
            margin: "0 24px 16px",
            padding: "16px",
            backgroundColor: "#F8FAFD",
            borderRadius: "12px",
          }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#1A1A1A",
              marginTop: 0,
              marginBottom: "8px",
            }}
          >
            Transaction History
          </h2>
          <p style={{ fontSize: "12px", color: "#666666", margin: 0 }}>
            No transaction data is available for this wallet.
          </p>
        </div>
      )}

      {/* Footer */}
      <div
        style={{
          marginTop: "24px",
          marginBottom: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div
          style={{ width: "100%", height: "2px", backgroundColor: "#2F7AD3" }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 24px",
            fontSize: "10px",
            color: "#999999",
          }}
        >
          <span>
            {/* Changed from "Generated by" to "Created by" */}
            Created by MonChain - {format(new Date(), "MMM dd, yyyy HH:mm")}
          </span>
          <span>Page 1/1</span>
        </div>
      </div>
    </div>
  );
};

export default ReportTemplate;
