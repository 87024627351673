import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "@/store/modalSlice";
import { resend2FAOTP } from "@/store/userSlice";
import { useToast } from "@/components/Toast/Toast";

interface GenericOtpModalProps {
  title: string;
  description: string;
  method: string;
  onSubmit: (otp: string) => Promise<void>;
  onClose?: () => void;
  canResend?: boolean;
  submitButtonText?: string;
  submitButtonColor?: string;
}

const GenericOtpModal: React.FC<GenericOtpModalProps> = ({
  title,
  description,
  method,
  onSubmit,
  onClose,
  canResend = true,
  submitButtonText = "Verify OTP",
  submitButtonColor = "bg-primary hover:bg-primary-hover",
}) => {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const handleResendOTP = async () => {
    if (method !== "email" || !canResend) return;

    setIsResending(true);
    try {
      await dispatch(resend2FAOTP(method)).unwrap();
      addToast("Success", "OTP has been resent to your email", "success");
    } catch (error) {
      addToast("Error", "Failed to resend OTP", "error");
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async () => {
    if (!otp || otp.length !== 6) {
      addToast("Error", "Please enter a valid 6-digit OTP", "error");
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(otp);
      // Modal will be closed by the calling component if needed
    } catch (error) {
      // Error handling is done by the calling component
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModal());
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4">
      <div className="bg-white rounded-xl p-6 max-w-[400px] w-full relative">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 text-sm mb-6">{description}</p>

        <div className="mb-6">
          <input
            type="text"
            maxLength={6}
            value={otp}
            onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ""))}
            placeholder="Enter 6-digit OTP"
            className="w-full px-4 py-2.5 rounded-lg border border-gray-200 text-gray-900 focus:outline-none focus:border-primary text-center text-lg tracking-wider"
          />
        </div>

        {method === "email" && canResend && (
          <div className="flex justify-center mb-6">
            <button
              onClick={handleResendOTP}
              disabled={isResending}
              className="text-primary hover:text-primary-hover transition-colors text-sm"
            >
              {isResending ? "Resending..." : "Resend OTP"}
            </button>
          </div>
        )}

        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!otp || otp.length !== 6 || isSubmitting}
            className={`
              px-6 py-2 ${submitButtonColor} text-white rounded-lg
              transition-colors
              disabled:opacity-50 disabled:cursor-not-allowed
              flex items-center justify-center min-w-[100px]
            `}
          >
            {isSubmitting ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full" />
                <span>Verifying...</span>
              </div>
            ) : (
              submitButtonText
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenericOtpModal; 