import { store } from '@/store/store';
import { openModal } from '@/store/modalSlice';
import { MODAL_TYPES } from '@/constants/modalTypes';

export const PROTECTED_ROUTES = [
  '/dashboard',
  '/wallet-evaluation-history',
  '/profile',
  '/support',
  '/wallet-evaluation'
];

export const handleProtectedRoute = (path: string): boolean => {
  const state = store.getState();
  const isAuthenticated = state.authSlice.isAuthenticated;

  const isProtectedRoute = PROTECTED_ROUTES.some(route => 
    path.startsWith(route)
  );

  if (isProtectedRoute && !isAuthenticated) {
    console.log('Dispatching openModal for AUTH_REQUIRED from protectedRoute');
    store.dispatch(openModal({ type: MODAL_TYPES.AUTH_REQUIRED, props: {} }));
    return false;
  }

  return true;
}; 