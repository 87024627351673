// store/walletSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface RiskBar {
  title: string;
  value: string;
  percentage: number;
  color: "bg-status-error" | "bg-status-success" | "bg-status-warning";
}

export interface Wallet {
  id: number;
  address: string;
  avatar: string;
  score: number;
  status: string;
  lastSync: string;
  timestamp: string;
  riskBars: RiskBar[];
  aiInsights: string;
}

interface WalletState {
  wallets: Wallet[];
  loading: boolean;
  error: string | null;
  selectedWallet: Wallet | null;
  sortOption: 'newest' | 'oldest';
  searchTerm: string;
}

const initialState: WalletState = {
  wallets: [],
  loading: false,
  error: null,
  selectedWallet: null,
  sortOption: 'newest',
  searchTerm: '',
};

export const fetchWallets = createAsyncThunk(
  'wallet/fetchWallets',
  async () => {
    const response = await axios.get('/api/wallets');
    return response.data.wallets;
  }
);

export const fetchWalletByAddress = createAsyncThunk(
  'wallet/fetchWalletByAddress',
  async (address: string, { getState }) => {
    const state: any = getState();
    const existingWallet = state.wallet.wallets.find(
      (w: Wallet) => w.address === address
    );
    if (existingWallet) {
      return existingWallet;
    }
    const response = await axios.get('/api/wallets');
    const wallet = response.data.wallets.find((w: Wallet) => w.address === address);
    if (!wallet) {
      throw new Error('Wallet not found');
    }
    return wallet;
  }
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearSelectedWallet: (state) => {
      state.selectedWallet = null;
    },
    setSortOption: (state, action: PayloadAction<'newest' | 'oldest'>) => {
      state.sortOption = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    clearFilters: (state) => {
      state.searchTerm = '';
      state.sortOption = 'newest';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWallets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        state.loading = false;
        state.wallets = action.payload;
      })
      .addCase(fetchWallets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch wallets';
      })
      .addCase(fetchWalletByAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletByAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedWallet = action.payload;
        if (!state.wallets.some(wallet => wallet.address === action.payload.address)) {
          state.wallets.push(action.payload);
        }
      })
      .addCase(fetchWalletByAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch wallet';
      });
  },
});

// Selectors
export const selectFilteredAndSortedWallets = (state: { wallet: WalletState }) => {
  const { wallets, searchTerm, sortOption } = state.wallet;
  
  let filteredWallets = wallets;
  
  // Apply search filter
  if (searchTerm) {
    filteredWallets = wallets.filter(wallet =>
      wallet?.address?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }

  // Apply sorting
  return [...filteredWallets].sort((a, b) => {
    const dateA = new Date(a.lastSync);
    const dateB = new Date(b.lastSync);
    return sortOption === 'newest'
      ? dateB.getTime() - dateA.getTime()
      : dateA.getTime() - dateB.getTime();
  });
};

export const { 
  clearSelectedWallet, 
  setSortOption, 
  setSearchTerm, 
  clearFilters 
} = walletSlice.actions;

export default walletSlice.reducer;