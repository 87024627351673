'use client'
import React, { createContext, useContext, useState } from 'react';
import ErrorDialog from '@/components/ui/ErrorDialog';

interface ErrorContextType {
  showError: () => void;
  hideError: () => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const showError = () => setIsErrorVisible(true);
  const hideError = () => setIsErrorVisible(false);

  return (
    <ErrorContext.Provider value={{ showError, hideError }}>
      {children}
      <ErrorDialog isOpen={isErrorVisible} onClose={hideError} />
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};