'use client';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store/store';
import { closeModal } from '@/store/modalSlice';
import { MODAL_TYPES } from '@/constants/modalTypes';
import PasswordOtpModal from '@/components/Modals/PasswordOtpModal';
import GenericOtpModal from '@/components/Modals/GenericOtpModal';
import { AuthModal } from '../Modals/AuthModal';
import ConnectSocialModal from '@/components/Modals/ConnectSocialModal';
import DeleteAccountModal from '@/components/Modals/DeleteAccountModal';
import ExportModal from '@/components/Modals/ExportModal';

// Import all your modal components

const ModalRegistry = () => {
  const { type, props } = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  if (!type) return null;

  // Add the onClose prop to all modals
  const modalProps = {
    ...props,
    onClose: handleClose,
  };

  // Render the appropriate modal based on the type
  switch (type) {
    case MODAL_TYPES.PASSWORD_OTP_MODAL:
      return <PasswordOtpModal {...modalProps} />;
    case MODAL_TYPES.GENERIC_OTP_MODAL:
      return <GenericOtpModal {...modalProps} />;
    case MODAL_TYPES.AUTH_REQUIRED:
      return <AuthModal {...modalProps} />;
    case MODAL_TYPES.CONNECT_SOCIAL_MODAL:
      return <ConnectSocialModal {...modalProps} />;
    case MODAL_TYPES.DELETE_ACCOUNT_MODAL:
      return <DeleteAccountModal {...modalProps} />;
    case MODAL_TYPES.EXPORT_MODAL:
      return <ExportModal {...modalProps} />;
    default:
      return null;
  }
};

export default ModalRegistry; 