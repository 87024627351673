'use client'
import React, { useEffect } from 'react';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { fetchCryptoPrices } from '@/store/cryptoSlice';
import Marquee from "react-fast-marquee";
import './styles.scss';

interface CryptoItemProps {
  symbol: string;
  price: string;
  currency: string;
  icon: string;
}

const CryptoTicker = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { prices, loading } = useSelector((state: RootState) => state.crypto);

  // Sort prices by rank
    const sortedPrices = [...prices].sort((a, b) => a.rank - b.rank);

  useEffect(() => {
    // Fetch real data initially
    dispatch(fetchCryptoPrices());

    // Set up polling for real-time updates every 30 seconds
    const interval = setInterval(() => {
      dispatch(fetchCryptoPrices());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const CryptoItem: React.FC<CryptoItemProps> = ({ symbol, price, currency, icon }) => (
    <div className="crypto-item">
      <div className="crypto-icon">
        <Image
          src={icon}
          alt={symbol}
          width={20}
          height={20}
          className="icon"
        />
      </div>
      <span className="crypto-text">
        {symbol} - {price || '---'} {currency}
      </span>
    </div>
  );

  // Show loading state if data is not ready
  if (loading || prices.length === 0) {
    return (
      <div className="marquee-container">
        <div className="marquee-content">
          {[
            { symbol: 'BTC', currency: 'USD', icon: '/icons/btc.svg' },
            { symbol: 'ETH', currency: 'USD', icon: '/icons/eth1.svg' },
            { symbol: 'USDT', currency: 'USD', icon: '/icons/usdt.svg' },
          ].map((crypto, index) => (
            <CryptoItem
              key={index}
              symbol={crypto.symbol}
              price="Loading..."
              currency={crypto.currency}
              icon={crypto.icon}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="marquee-container">
      <Marquee className="marquee-content" pauseOnHover={true}>
        {sortedPrices.map((crypto, index) => (
          <CryptoItem
            key={index}
            symbol={crypto.symbol}
            price={crypto.price}
            currency={crypto.currency}
            icon={crypto.icon}
          />
        ))}
      </Marquee>
    </div>
  );
};

export default CryptoTicker;