// store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './walletSlice';
import walletConnectionReducer from './walletConnectionSlice';
import fraudEvaluationReducer from './fraudEvaluationSlice';
import authSlice from './authSlice';
import userSlice from './userSlice';
import modalReducer from './modalSlice';
import fraudCasesReducer from './fraudCasesSlice';
import cryptoReducer from './cryptoSlice';

export const store = configureStore({
  reducer: {
    userSlice: userSlice,
    authSlice: authSlice,
    wallet: walletReducer,
    walletConnection: walletConnectionReducer,
    fraudEvaluation: fraudEvaluationReducer,
    modal: modalReducer,
    fraudCases: fraudCasesReducer,
    crypto: cryptoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
