import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { closeModal } from "@/store/modalSlice";
import { get2FAOTP } from "@/store/userSlice";
import { useToast } from "@/components/Toast/Toast";

interface PasswordOtpModalProps {
  onSubmit: (otp: string) => Promise<void>;
}

const PasswordOtpModal: React.FC<PasswordOtpModalProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState("");
  const [isResending, setIsResending] = useState(false);
  
  const { currentUser } = useSelector((state: RootState) => state.userSlice);

  // Determine active 2FA method
  const activeMethods = currentUser?.account_settings?.two_factor_activations || [];
  const activeMethod = activeMethods.find(
    (m) => m.status === "activated"
  )?.method;

  const handleResendOTP = async () => {
    if (activeMethod !== "email") return;

    setIsResending(true);
    try {
      await dispatch(get2FAOTP("email")).unwrap();
      addToast("Success", "OTP has been resent to your email", "success");
    } catch (error) {
      addToast("Error", "Failed to resend OTP", "error");
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async () => {
    if (!otp || otp.length !== 6) {
      addToast("Error", "Please enter a valid 6-digit OTP", "error");
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(otp);
      dispatch(closeModal());
    } catch (error) {
      // Error handling is done in the parent component
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4">
      <div className="bg-white rounded-xl p-6 max-w-[400px] w-full relative">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Verify OTP to Change Password
        </h3>
        <p className="text-gray-600 text-sm mb-6">
          {activeMethod === "email"
            ? "Please enter the 6-digit verification code sent to your email."
            : "Please enter the 6-digit code from your authenticator app."}
        </p>

        <div className="mb-6">
          <input
            type="text"
            maxLength={6}
            value={otp}
            onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ""))}
            placeholder="Enter 6-digit OTP"
            className="w-full px-4 py-2.5 rounded-lg border border-gray-200 text-gray-900 focus:outline-none focus:border-primary text-center text-lg tracking-wider"
          />
        </div>

        {activeMethod === "email" && (
          <div className="flex justify-center mb-6">
            <button
              onClick={handleResendOTP}
              disabled={isResending}
              className="text-primary hover:text-primary-hover transition-colors text-sm"
            >
              {isResending ? "Resending..." : "Resend OTP"}
            </button>
          </div>
        )}

        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!otp || otp.length !== 6 || isSubmitting}
            className={`
              px-6 py-2 bg-primary text-white rounded-lg
              hover:bg-primary-hover transition-colors
              disabled:opacity-50 disabled:cursor-not-allowed
              flex items-center justify-center min-w-[100px]
            `}
          >
            {isSubmitting ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full" />
                <span>Verifying...</span>
              </div>
            ) : (
              "Verify OTP"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordOtpModal; 