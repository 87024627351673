import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cryptoService, CryptoPrice } from '@/services/api/cryptoService';

interface CryptoState {
  prices: CryptoPrice[];
  loading: boolean;
  error: string | null;
  lastUpdated: string | null;
}

const initialState: CryptoState = {
  prices: [],
  loading: false,
  error: null,
  lastUpdated: null
};

export const fetchCryptoPrices = createAsyncThunk(
  'crypto/fetchPrices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await cryptoService.getCryptoPrices();
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch crypto prices');
    }
  }
);

const cryptoSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoPrices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCryptoPrices.fulfilled, (state, action) => {
        state.loading = false;
        state.prices = action.payload;
        state.lastUpdated = new Date().toISOString();
      })
      .addCase(fetchCryptoPrices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export default cryptoSlice.reducer; 