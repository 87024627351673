import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "@/store/modalSlice";
import { useToast } from "@/components/Toast/Toast";
import Image from "next/image";

interface ConnectSocialModalProps {
  availablePlatforms: {
    id: string;
    name: string;
    icon: string;
    placeholder: string;
  }[];
  onConnect: (platform: string, username: string) => Promise<void>;
  onClose?: () => void;
}

const ConnectSocialModal: React.FC<ConnectSocialModalProps> = ({
  availablePlatforms,
  onConnect,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [selectedPlatform, setSelectedPlatform] = useState(availablePlatforms[0]);
  const [username, setUsername] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = async () => {
    if (!username.trim()) {
      addToast("Error", "Please enter a username", "error");
      return;
    }

    setIsConnecting(true);
    try {
      await onConnect(selectedPlatform.id, username);
      handleClose();
    } catch (error) {
      addToast("Error", "Failed to connect account", "error");
      console.error(error);
    } finally {
      setIsConnecting(false);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModal());
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 px-4">
      <div className="bg-white rounded-xl p-6 max-w-[450px] w-full">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Connect Social Account
        </h3>
        <p className="text-gray-600 text-sm mb-6">
          Select a platform and enter your username to connect your social media account.
        </p>

        {/* Platform Select */}
        <div className="mb-5">
          <label className="block text-sm text-gray-700 mb-2">Select Platform</label>
          <div className="relative">
            <select
              value={selectedPlatform.id}
              onChange={(e) => {
                const platform = availablePlatforms.find(p => p.id === e.target.value);
                if (platform) setSelectedPlatform(platform);
              }}
              className="w-full px-4 py-2.5 pr-10 rounded-lg border border-gray-200 text-gray-900 focus:outline-none focus:border-primary appearance-none"
            >
              {availablePlatforms.map(platform => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
            </select>
            <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none">
              <Image
                src="/images/downarrow.svg"
                width={12}
                height={12}
                alt=""
                className="w-4 h-4 opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Selected Platform Preview */}
        <div className="mb-5 p-3 bg-gray-50 rounded-lg flex items-center gap-3">
          <Image
            src={selectedPlatform.icon}
            width={32}
            height={32}
            alt={selectedPlatform.name}
            className="w-8 h-8"
          />
          <div>
            <p className="text-sm font-medium text-gray-900">{selectedPlatform.name}</p>
            <p className="text-xs text-gray-500">Enter your username below</p>
          </div>
        </div>

        {/* Username Input */}
        <div className="mb-6">
          <label className="block text-sm text-gray-700 mb-2">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={selectedPlatform.placeholder}
            className="w-full px-4 py-2.5 rounded-lg border border-gray-200 text-gray-900 focus:outline-none focus:border-primary"
          />
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-full"
            disabled={isConnecting}
          >
            Cancel
          </button>
          <button
            onClick={handleConnect}
            disabled={!username.trim() || isConnecting}
            className="px-6 py-2 bg-primary text-white rounded-full hover:bg-primary-hover transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[100px]"
          >
            {isConnecting ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin w-4 h-4 border-2 border-white border-t-transparent rounded-full" />
                <span>Connecting...</span>
              </div>
            ) : (
              "Connect"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConnectSocialModal; 