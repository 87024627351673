// components/TermsModal.tsx
'use client';
import React, { useState } from 'react';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState('overview');

  const sections = [
    { id: "overview", title: "Overview" },
    { id: "introduction", title: "Introduction" },
    { id: "account", title: "Account Terms" },
    { id: "payment", title: "Payment Terms" },
    { id: "usage", title: "Usage Guidelines" },
    { id: "privacy", title: "Privacy & Data" },
    { id: "termination", title: "Termination" },
    { id: "liability", title: "Limitation of Liability" },
    { id: "changes", title: "Changes to Terms" },
    { id: "contact", title: "Contact Information" },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      {/* Modal Container */}
      <div className="fixed inset-0 z-[10000] overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="bg-white rounded-2xl w-full max-w-6xl max-h-[90vh] overflow-hidden relative">
            {/* Header */}
            <div className="bg-[#2F7BD3] text-white p-6 relative">
              <h2 className="text-2xl font-semibold text-center">Terms of Services</h2>
              <p className="text-center text-white/80 mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </p>
              <button 
                onClick={onClose}
                className="absolute top-6 right-6 text-white hover:text-gray-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="flex h-[calc(90vh-120px)]">
              {/* Sidebar */}
              <div className="w-64 border-r p-6 overflow-y-auto">
                <nav className="space-y-3">
                  {sections.map((section) => (
                    <button
                      key={section.id}
                      onClick={() => setActiveSection(section.id)}
                      className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${
                        activeSection === section.id
                          ? 'bg-blue-50 text-[#0066FF]'
                          : 'text-[#666666] hover:bg-gray-50'
                      }`}
                    >
                      {section.title}
                    </button>
                  ))}
                </nav>
              </div>

              {/* Main Content */}
              <div className="flex-1 p-6 overflow-y-auto">
                <div className="max-w-3xl mx-auto">
                  {sections.map((section) => (
                    <section
                      key={section.id}
                      id={section.id}
                      className={`mb-8 ${activeSection === section.id ? '' : 'hidden'}`}
                    >
                      <h3 className="text-xl font-semibold mb-4 text-[#1A1A1A]">
                        {section.title}
                      </h3>
                      <div className="prose prose-sm text-[#666666] space-y-4">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                        <p>
                          Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                      </div>
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;