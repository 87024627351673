import axiosInstance from './axios';

export interface CryptoPrice {
  symbol: string;
  price: string;
  currency: string;
  icon: string;
  rank: number;
}

export interface CryptoPricesResponse {
  data: CryptoPrice[];
}

export const cryptoService = {
  async getCryptoPrices(): Promise<CryptoPricesResponse> {
    try {
      const response = await axiosInstance.get('/api/crypto/prices');
      return response.data;
    } catch (error) {
      console.error("Error fetching crypto prices:", error);
      throw error;
    }
  }
}; 