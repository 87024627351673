import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fraudCasesService, FraudCaseRequest } from '@/services/api/fraudCasesService';

export const submitFraudCase = createAsyncThunk(
  'fraudCases/submit',
  async (data: FraudCaseRequest, { rejectWithValue }) => {
    try {
      return await fraudCasesService.submitFraudCase(data);
    } catch (error) {
      return rejectWithValue('Failed to submit fraud case');
    }
  }
);

const fraudCasesSlice = createSlice({
  name: 'fraudCases',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitFraudCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitFraudCase.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(submitFraudCase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default fraudCasesSlice.reducer; 