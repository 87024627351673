// components/PrivacyPolicyModal.tsx
'use client';
import React, { useState } from 'react';

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState('overview');

  const sections = [
    {id: 'overview', title: 'Overview'},
    {id: 'summary', title: 'Summary of Key Points'},
    {id: 'table-of-contents', title: 'Table of Contents'},
    {id: 'personal-information', title: 'What Information Do We Collect?'},
    {id: 'personal-information-processing', title: 'How Do We Process Your Information?'},
    {id: 'legal-basis', title: 'What Legal Basis Do We Have for Processing Your Personal Information?'},
    {id: 'personal-information-sharing', title: 'When And With Whom Do We Share Your Personal Information?'},
    {id: 'cookies', title: 'Do We Use Cookies And Other Tracking Technologies?'},
    {id: 'artificial-intelligence', title: 'Do We Offer Artificial Intelligence Products?'},
    {id: 'personal-information-duration', title: 'How Long Do We Keep Your Information?'},
    {id: 'personal-information-safety', title: 'How Do We Keep Your Information Safe?'},
    {id: 'personal-information-minors', title: 'Do We Collect Information From Minors?'},
    {id: 'privacy-rights', title: 'What Are Your Privacy Rights?'},
    {id: 'do-not-track', title: 'Controls for Do-Not-Track Features'},
    {id: 'us-citizens', title: 'Do United States Citizens Have Specific Privacy Rights?'},
    {id: 'policy-updates', title: 'Do We Make Updates to This Policy?'},
    {id: 'contact-us', title: 'How Can You Contact Us About This Policy?'},
    {id: 'data-update-and-deletion', title: 'How Can You Review, Update, or Delete the Data We Collect From You?'},
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      {/* Modal Container */}
      <div className="fixed inset-0 z-[10000] overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div className="bg-white rounded-2xl w-full max-w-6xl max-h-[90vh] overflow-hidden relative">
            {/* Header Section */}
            <div className="bg-[#2F7BD3] text-white p-6 relative">
              <h2 className="text-2xl font-semibold text-center">Privacy Policy</h2>
              <p className="text-center text-white/80 mt-2">
                Last updated March 25, 2025
              </p>
              <button 
                onClick={onClose}
                className="absolute top-6 right-6 text-white hover:text-gray-200"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="flex h-[calc(90vh-120px)]">
              {/* Sidebar */}
              <div className="w-64 border-r p-6 overflow-y-auto">
                <nav className="space-y-3">
                  {sections.map((section) => (
                    <button
                      key={section.id}
                      onClick={() => setActiveSection(section.id)}
                      className={`w-full text-left px-3 py-2 rounded-lg transition-colors ${
                        activeSection === section.id
                          ? 'bg-blue-50 text-[#0066FF]'
                          : 'text-[#666666] hover:bg-gray-50'
                      }`}
                    >
                      {section.title}
                    </button>
                  ))}
                </nav>
              </div>

              {/* Main Content */}
              <div className="flex-1 p-6 overflow-y-auto">
                <div className="max-w-3xl mx-auto">
                  {/* Overview Section */}
                  <section className={`mb-8 ${activeSection === 'overview' ? '' : 'hidden'}`}>
                    <h3 className="text-xl font-semibold mb-4 text-[#1A1A1A]">Overview</h3>
                    <div className="prose prose-sm text-[#666666] space-y-4">
                      <p>
                        Lorem ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry standard dummy text ever since the 1500s, 
                        when an unknown printer took a galley of type and scrambled it to make a 
                        type specimen book.
                      </p>
                      <p>
                        It has survived not only five centuries but also the leap into electronic 
                        typesetting, remaining essentially unchanged.
                      </p>
                    </div>
                  </section>

                  {/* Privacy Section */}
                  <section className={`mb-8 ${activeSection === 'privacy' ? '' : 'hidden'}`}>
                    <h3 className="text-xl font-semibold mb-4 text-[#1A1A1A]">
                      Your Privacy Matters
                    </h3>
                    <div className="prose prose-sm text-[#666666] space-y-4">
                      <p>
                        Lorem ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                      </p>
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has 
                        roots in a piece of classical Latin literature from 45 BC, making it over 
                        2000 years old.
                      </p>
                    </div>
                  </section>

                  {/* Other Sections */}
                  {sections.slice(2).map((section) => (
                    <section 
                      key={section.id} 
                      className={`mb-8 ${activeSection === section.id ? '' : 'hidden'}`}
                    >
                      <h3 className="text-xl font-semibold mb-4 text-[#1A1A1A]">
                        {section.title}
                      </h3>
                      <div className="prose prose-sm text-[#666666] space-y-4">
                        <p>
                          Lorem ipsum is simply dummy text of the printing and typesetting industry. 
                          Lorem Ipsum has been the industry standard dummy text ever since the 1500s.
                        </p>
                        <p>
                          It has survived not only five centuries but also the leap into electronic 
                          typesetting, remaining essentially unchanged. It was popularised in the 1960s 
                          with the release of Letraset sheets containing Lorem Ipsum passages.
                        </p>
                      </div>
                    </section>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;